import { useCart } from 'context/cart/cart.context';
import FixingBugs from '../images/fixing_bugs.svg'
import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import Log from 'logger';
import { useUser } from 'store/utils/hooks';

const PageError:React.ComponentType<FallbackProps> = ({error}) => {
  const {resetCart, items} = useCart()
  const user = useUser()
  const onReset = () => {
    resetCart()
    window.location.href = window.location.origin
  }
  useEffect(()=>{
    Log.e({
      error,
      data:{
        cart:items,
        user
      },
      routes:[
        {
          name:window.location.pathname,
          params:window.location.search
        }
      ],
      labels:['logger','error']
    })
  },[])
  return <div className="h-screen">
    <div className="container h-full p-5 lg:p-20">
      {/* HEADER */}
      <header className="text-center mx-auto gap-4 items-center justify-center h-full flex flex-col">
        {/* <NcImage src={I404Png} /> */}
        <img src={FixingBugs} alt='fixing_bugs' className='h-full'/>
        <span className="block uppercase text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          Ups! Algo salió mal :({" "}
        </span>
        <div className="pt-8">
            <button onClick={onReset} className={`py-4 px-8 text-lg font-semibold uppercase rounded-full ttnc-ButtonPrimary disabled:bg-opacity-90 bg-[#006B7E] dark:bg-slate-100 hover:bg-slate-800 text-slate-50 dark:text-slate-800 shadow-xl`}>
                Reestablecer la página
            </button>
          {/* <ButtonPrimary href="/">Return Home Page</ButtonPrimary> */}
        </div>
      </header>
    </div>
  </div>
};

export default PageError;
