import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import AccordionInfo, { RichTextFormat } from "./AccordionInfo";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { CollegeProduct } from "data/data";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import SectionPromo2 from "components/SectionPromo2";
import ModalViewAllReviews from "./ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";
import { useProducts, useUser } from "store/utils/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getVariations, getVariationsByVariations } from "utils/getVariations";
import {
  useGetProductQuery,
  useGetVariationsQuery,
} from "store/api/productsApi";
import { Product } from "data/types";
import { ProductAttributes } from "components/ProductsAttributes";
import { generateCartItem } from "utils/generateCartItems";
import { useCart } from "context/cart/cart.context";
import NcImage from "shared/NcImage/NcImage";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import parse from "html-react-parser";
import { StockWarning, Warning } from "./GroupedDetailPage";

export interface ProductDetailPageProps {
  className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  // const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
  // const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];
  const [params] = useSearchParams();
  const products = useProducts() as CollegeProduct[];
  const { data: apiProduct } = useGetProductQuery(Number(params.get("id")));
  const user = useUser();
  const navigate = useNavigate();
  const { items, addItemToCart } = useCart();
  const data = (products || apiProduct) && {
    ...(products?.find((it) => it.id === Number(params.get("id"))) || {}),
    ...(apiProduct || {}),
  };
  const { data: productVariations, isLoading: isLoadingVariations } =
    useGetVariationsQuery(params.get("id"));
  const [attributes, setAttributes] = useState<{ [key: string]: string }>({});
  const variations = getVariations(data?.attributes);
  // const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [isShowGuide, setIsShowGuide] = React.useState(false);
  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
    useState(false);
  const product = Object.keys(variations).length
    ? productVariations?.find((it: Product) =>
        it.attributes.every(
          ({ name, terms }) => attributes[name] === terms[0].name
        )
      ) || data
    : data;
  const itemCart = items.find(({ id }) => {
    return product?.id === id;
  });
  const isInCart =
    data &&
    items.some(({ pivot_parent_id, parent_id, id }) => {
      if (data.type === "grouped") {
        return data.id === pivot_parent_id;
      }
      if (data.type === "variable") {
        return data.id === parent_id;
      }
      return data.id === id;
    });
  const isSelected = Object.keys(variations).length
    ? Object.keys(attributes) &&
      Object.keys(variations).every((variation) =>
        attributes.hasOwnProperty(variation)
      )
    : true;

  useEffect(() => {
    setQualitySelected(1);
  }, [params]);

  function handleAttribute(attribute: { [key: string]: string }) {
    setAttributes((prev) => ({
      ...prev,
      ...attribute,
    }));
  }

  const addToCart = () => {
    const item = generateCartItem(
      {
        ...data,
        ...product,
        name: data ? data.name : "",
        limit_store: data.limit_store,
        // store_without_stock: data.store_without_stock,
      },
      attributes
    );
    console.log({ item });
    addItemToCart(item, qualitySelected);
    notifyAddTocart();
  };

  const notifyAddTocart = () => {
    const item = generateCartItem(
      { ...data, ...product, name: data ? data.name : "" },
      attributes
    );
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={product?.image ? product?.image : ""}
          qualitySelected={qualitySelected}
          show={t.visible}
          name={item.name}
          attributes={item.attributes}
          price={product?.price ? product?.price : 0}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const hideSizeGuide = () => {
    setIsShowGuide(false);
  };

  const showSizeGuide = () => {
    setIsShowGuide(true);
  };

  // const renderStatus = () => {
  //   if (!status) {
  //     return null;
  //   }
  //   const CLASSES =
  //     "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300";
  //   if (status === "New in") {
  //     return (
  //       <div className={CLASSES}>
  //         <SparklesIcon className="w-3.5 h-3.5" />
  //         <span className="ml-1 leading-none">{status}</span>
  //       </div>
  //     );
  //   }
  //   if (status === "50% Discount") {
  //     return (
  //       <div className={CLASSES}>
  //         <IconDiscount className="w-3.5 h-3.5" />
  //         <span className="ml-1 leading-none">{status}</span>
  //       </div>
  //     );
  //   }
  //   if (status === "Sold Out") {
  //     return (
  //       <div className={CLASSES}>
  //         <NoSymbolIcon className="w-3.5 h-3.5" />
  //         <span className="ml-1 leading-none">{status}</span>
  //       </div>
  //     );
  //   }
  //   if (status === "limited edition") {
  //     return (
  //       <div className={CLASSES}>
  //         <ClockIcon className="w-3.5 h-3.5" />
  //         <span className="ml-1 leading-none">{status}</span>
  //       </div>
  //     );
  //   }
  //   return null;
  // };
  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl sm:text-3xl font-semibold">{data?.name}</h2>
          {data?.short_description && (
            <RichTextFormat className="mt-4">
              {parse(data?.short_description)}
            </RichTextFormat>
          )}
          <div>
            {variations && Object.keys(variations).length !== 0 && (
              <div className={`mt-4`}>
                {Object.keys(variations).map((variation) => {
                  return (
                    <ProductAttributes
                      key={variation}
                      title={variation}
                      attributes={variations[variation]}
                      active={attributes[variation]}
                      onClick={handleAttribute}
                    />
                  );
                })}
              </div>
            )}
          </div>

          {/*  */}
          {data?.size_guide && (
            <div className="pb-3 border-b border-gray-300">
              <p
                onClick={showSizeGuide}
                className="font-semibold transition-all underline hover:opacity-50 cursor-zoom-in hover:no-underline"
              >
                Guía de tallas
              </p>
            </div>
          )}

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}
            <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
              price={product?.price}
              price_html={product?.price_html}
              refund_price={product?.sale_price}
            />

            {/* <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div> */}

            <div className="flex items-center">
              {/* {(product as Product)?.stock_quantity || "No hay"} disponibles */}
              {/* <a
                href="#reviews"
                className="flex items-center text-sm font-medium"
              >
                <StarIcon className="w-5 h-5 pb-[1px] text-yellow-400" />
                <div className="ml-1.5 flex">
                  <span>4.9</span>
                  <span className="block mx-2">·</span>
                  <span className="text-slate-600 dark:text-slate-400 underline">
                    142 reviews
                  </span>
                </div>
              </a>
              <span className="hidden sm:block mx-2.5">·</span>
              <div className="hidden sm:flex items-center text-sm">
                <SparklesIcon className="w-3.5 h-3.5" /> */}
              {/* <span className="ml-1 leading-none">{status}</span> */}
              {/* </div> */}
            </div>
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        {/* <div className="">{renderVariants()}</div>
        <div className="">{renderSizeList()}</div> */}

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              defaultValue={qualitySelected}
              onChange={setQualitySelected}
              max={
                data?.limit_store
                  ? 1
                  : !product?.store_without_stock
                  ? product?.stock_quantity - (itemCart?.quantity || 0)
                  : undefined
              }
            />
          </div>
          <ButtonPrimary
            className="flex-1 flex-shrink-0"
            // onClick={() => {}}
            disabled={
              isLoadingVariations ||
              !isSelected ||
              (isInCart && data?.limit_store) ||
              ((itemCart?.quantity || 0) + qualitySelected >
                product?.stock_quantity &&
                !Boolean(product.store_without_stock))
            }
            onClick={user ? addToCart : () => navigate("/login")}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Agregar al carrito</span>
          </ButtonPrimary>
        </div>
        <div className="space-y-2">
          {product &&
            product?.stock_quantity <
              (itemCart?.quantity || 0) + qualitySelected &&
            isSelected && (
              <>
                {product.store_without_stock ? (
                  <StockWarning
                    noStock={(product as Product).stock_quantity === 0}
                  />
                ) : (
                  <Warning>
                    No hay stock suficiente para realizar esta compra.
                  </Warning>
                )}
              </>
            )}
          {Boolean(data?.limit_store) && (
            <Warning>
              Este producto está limitado a una compra por estudiante.
            </Warning>
          )}
        </div>
        {/*  */}
        <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>
        {/* ---------- 5 ----------  */}
        {data && (
          <AccordionInfo
            data={[
              {
                name: "Envíos y Devoluciones",
                content: data?.description,
              },
              {
                name: "Normas de lavado y secado",
                content: data?.instructions,
              },
            ]}
          />
        )}

        {/* ---------- 6 ----------  */}
        {/* <div className="hidden xl:block">
          <Policy />
        </div> */}
      </div>
    );
  };

  return (
    <div className={`nc-ProductDetailPage ${className}`}>
      {/* MAIn */}
      <main className="container mt-5 lg:mt-11">
        <div className="lg:flex">
          {/* CONTENT */}
          <div className="w-full lg:w-[55%] ">
            {/* HEADING */}
            <div className="relative">
              <div className="aspect-w-16 aspect-h-16">
                <img
                  src={product?.image}
                  className="w-full rounded-2xl object-cover"
                  alt="product detail 1"
                />
              </div>
              {/* {renderStatus()} */}
              {/* META FAVORITES */}
              {/* <LikeButton className="absolute right-3 top-3 " /> */}
            </div>
            {/* <div className="grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
              {product?.image
                .map((item: { src: string }, index: number) => {
                  return (
                    <div
                      key={index}
                      className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-16"
                    >
                      <img
                        src={item.src}
                        className="w-full rounded-2xl object-cover"
                        alt="product detail 1"
                      />
                    </div>
                  );
                })}
            </div> */}
          </div>

          {/* SIDEBAR */}
          <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
            {renderSectionContent()}
          </div>
        </div>

        {/* DETAIL AND REVIEW */}
        <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
          {/* <div className="block xl:hidden">
            <Policy />
          </div> */}

          {/* {renderDetailSection()} */}

          <hr className="border-slate-200 dark:border-slate-700" />

          {/* {renderReviews()} */}

          {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

          {/* OTHER SECTION */}
          <div className="relative">
            <SectionSliderProductCard
              heading="Otros productos"
              subHeading=""
              headingFontClassName="text-2xl font-semibold"
              headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
            />
          </div>

          {/* SECTION */}
          <div className="pb-20 xl:pb-28 lg:pt-14">
            <SectionPromo2 />
          </div>
        </div>
      </main>

      {/* MODAL VIEW ALL REVIEW */}
      <ModalViewAllReviews
        show={isOpenModalViewAllReviews}
        onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
      />
      <Dialog open={isShowGuide} onClose={hideSizeGuide}>
        <div className="flex justify-between items-center">
          <DialogTitle>
            <b>Guía de Tallas:</b> {data?.name}
          </DialogTitle>
          <ButtonClose onClick={hideSizeGuide} className="mr-4"></ButtonClose>
        </div>
        <DialogContent>
          <NcImage src={data?.size_guide} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProductDetailPage;
