import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { useCollege } from "store/utils/hooks";
import { Link } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const Footer: React.FC = () => {
  const college = useCollege();
  console.log({college})
  const widgetMenus: WidgetFooterMenu[] = [
    {
      id: "5",
      title: "Enlaces Importantes",
      menus: [
        { href: "/faqs", label: "Faqs" },
        { href: "/contact", label: "Contacto" },
        { href: "/devolutions", label: "Devoluciones" },
      ],
    },
    {
      id: "1",
      title: "Privacidad",
      menus: [
        { href: "/cookies", label: "Política de Cookies" },
        { href: "/privacy", label: "Política de Privacidad" },
        { href: "/terms", label: "Términos y Condiciones" },
      ],
    },
    {
      id: "2",
      title: "Contacta con nosotros",
      menus: [
        { href: "#", label: college?.name },
        { href: "#", label: college?.phone_number },
        { href: "#", label: college?.email },
        {label: "Atención al cliente", className:"font-semibold text-neutral-700 dark:text-neutral-200" },
        {label: "9.00h - 13.00h / 16.00h - 18.00h"}
      ],
    },
  ];
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className={`text-sm ${index===2 ?"rounded-xl bg-[#E3EBF8] p-4":""} h-fit`}>
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            item.href ? <li key={index}>
              <Link key={index} to={item.href} className="break-words text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
                {item.label}
              </Link>
            </li> :  <li key={index} className={`break-words text-neutral-6000 dark:text-neutral-300 ${item.className || ""}`}>
                {item.label}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-20 lg:pt-28 lg:pb-24 border-t border-neutral-200 dark:border-neutral-700 bg-[#EFF5FF]">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-10 ">
        {/* <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div>
        </div> */}
        <Logo imgClassName="!max-w-full !max-h-[40%]"/>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
