import Loggers from 'logger-issues'

const Log = new Loggers.GithubLogger(
    process.env.REACT_APP_GITHUB_TOKEN || "",
    process.env.REACT_APP_REPO_OWNER || "",
    process.env.REACT_APP_REPO_NAME || "",
    true,
    (error)=>{
        console.error("ON_ERROR",{error})
    },
    (warn)=>{
        console.warn("ON_WARN",{warn})
    }
)

export default Log