import { CustomLink } from "data/types";
import React, { FC } from "react";
import { Link, useSearchParams } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";

const DEMO_PAGINATION: CustomLink[] = [
  {
    label: "1",
    href: "#",
  },
  {
    label: "2",
    href: "#",
  },
  {
    label: "3",
    href: "#",
  },
  {
    label: "4",
    href: "#",
  },
];

export interface PaginationProps {
  className?: string;
}

const Pagination: FC<PaginationProps> = ({ amount, className = "" }) => {
  const [params, setParams] = useSearchParams()
  const renderItem = (pag: number, index: number) => {
    if ((params.get('page') || 1) == pag) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          onClick={()=>setParams(prev=>{
            prev.set('page',pag)
            return prev
          })}
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {pag}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <div
        key={index}
        onClick={()=>setParams(prev=>{
          prev.set('page',pag)
          return prev
        })}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
      >
        {pag}
      </div>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {
        amount && amount > 1 && Array.from({length:amount}).map((_,idx:number)=>renderItem(idx+1,idx))
      }
    </nav>
  );
};

export default Pagination;
