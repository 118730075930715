import Label from "components/Label/Label";
import { AddressForm } from "containers/AccountPage/AccountPage";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useUser } from "store/utils/hooks";
import { getBilling } from "utils/getBilling";
interface Props {
  title?:string;
  isActive: boolean;
  onCloseActive?: () => void;
  onOpenActive?: () => void;
  register?:any;
  prefixFields?: string;
  errors?:any;
  icon?: JSX.Element
  saveButtonLabel?:string
}

export const addressFields = ["address", "city", "potal_code", "city", "province"];

const ShippingAddress: FC<Props> = ({
  title = "Dirección",
  isActive,
  onCloseActive = (()=>{}),
  onOpenActive= (()=>{}),
  register,
  errors,
  icon,
  prefixFields = "",
  saveButtonLabel = "Continuar"
}) => {
  const user = useUser();
  const billing = user || {};
  const renderShippingAddress = () => {
    return (
      <div className={`border border-slate-200 dark:border-slate-700 rounded-xl ${addressFields.map((it)=>prefixFields+it).some((it)=>errors[it])?'border-red-500 border-1 transition-colors':''}`}>
        <div className="p-6 flex flex-col sm:flex-row items-start">
          <span className="hidden sm:block">
            {icon || <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>}
          </span>

          <div className="sm:ml-8">
            <h3 className=" text-slate-700 dark:text-slate-300 flex ">
              <span className="uppercase">{title}</span>
              <svg
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </h3>
            <div className="font-semibold mt-1 text-sm">
              <span className="">
                {billing[prefixFields + "address"]}, {billing[prefixFields + "city"]}, CP: {billing[prefixFields + "potal_code"]}, {billing[prefixFields + "province"]}
              </span>
            </div>
          </div>
          <ButtonSecondary
            sizeClass="py-2 px-4 "
            type="button"
            fontSize="text-sm font-medium"
            className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
            onClick={onOpenActive}
          >
            Editar
          </ButtonSecondary>
        </div>
        <div
          className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
            isActive ? "block" : "hidden"
          }`}
        >
          {/* ============ */}
          
          <AddressForm prefixFields={prefixFields} register={register} errors={errors}/>

          {/* ============ */}
          <div className="flex flex-col sm:flex-row pt-6">
            <ButtonPrimary
              className="sm:!px-7 shadow-none"
              onClick={onCloseActive}
              type="button"
            >
              {saveButtonLabel}
            </ButtonPrimary>
            {/* <ButtonSecondary
              className="mt-3 sm:mt-0 sm:ml-3"
              onClick={onCloseActive}
            >
              Cerrar
            </ButtonSecondary> */}
          </div>
        </div>
      </div>
    );
  };
  return renderShippingAddress();
};

export default ShippingAddress;
